<template>
<Alert></Alert>
</template>

<script>
// این صفحه نمایش هشدار ها در داشبورد می باشد
import Alert from '@/components/Alert.vue'

export default {
    name: 'AlertsView',
    components: {
        Alert,
    },
    data: function () {
        return {}
    },
}
</script>
